import { useMutation, useMutationState, useQueryClient } from "@tanstack/react-query"
import Cookies from "js-cookie"
import { parseDomain } from "parse-domain"

export function useSessionTermination() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['session-termination'],
    mutationFn: async (data) => {
      try {
        const prefix = new URL(process.env.REACT_APP_API_URL).hostname
        const key = `${prefix}_signedIn`
        Cookies.remove(key)
        const hostname = window.location.hostname
        const { domain, topLevelDomains } = parseDomain(hostname)
        Cookies.remove(key, {
          domain: [domain ?? '', ...topLevelDomains ?? []].join('.'),
        })

        return { ok: true }
      } catch (error) {
        throw Object.assign(error, {
          message: 'sorry, something went wrong'
        })
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'session-lookup',
        ],
      })
    },
  })
}

export function useSessionTerminationState() {
  const states = useMutationState({
    filters: {
      mutationKey: ['session-termination']
    },
    select: (mutation) => Object.assign(mutation.state, {
      isIdle: mutation.state.status === 'idle',
      isPending: mutation.state.status === 'pending',
      isSuccess: mutation.state.status === 'success',
      isError: mutation.state.status === 'error',
      isDone: mutation.state.status === 'success' ||
        mutation.state.status === 'error'
    }),
  })

  return states[states.length - 1] ?? {}
}