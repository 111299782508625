import { useEffect, useState } from "react"
import { useClientAvatarUploadState } from "../processes/client_avatar_upload"
import { useClientEmissionAssignmentState } from "../processes/client_emission_assignment"
import { useClientRegistrationState } from "../processes/client_registration"
import { useEligibilityCheckFinalizationState } from "../processes/eligibility_check_finalization"
import { useSessionTerminationState } from "../processes/session_termination"
import { useUserClientAssignment } from "../processes/user_client_assignment"
import { useEmissionSourcesSynchronizationState } from "../processes/emission_sources_synchronization"

export default function Toast() {
  const [response, setResponse] = useState()
  useEffect(() => {
    if (response?.message) alert(response?.message)
    if (response instanceof Error) console.error(response)
  }, [response])
  // const {
  //   sessionLookupResponse,
  // } = useSessionLookup()
  // useEffect(() => {
  //   setResponse(sessionLookupResponse)
  // }, [sessionLookupResponse])
  // const {
  //   profileLookupResponse,
  // } = useProfileLookup()
  // useEffect(() => {
  //   setResponse(profileLookupResponse)
  // }, [profileLookupResponse])
  // const {
  //   userSummariesLookupResponse,
  // } = useUserSummariesLookup()
  // useEffect(() => {
  //   setResponse(userSummariesLookupResponse)
  // }, [userSummariesLookupResponse])
  // const {
  //   clientSummariesLookupResponse,
  // } = useClientSummariesLookup()
  // useEffect(() => {
  //   setResponse(clientSummariesLookupResponse)
  // }, [clientSummariesLookupResponse])
  // const {
  //   emissionCategorySummariesLookupResponse,
  // } = useEmissionCategorySummariesLookup()
  // useEffect(() => {
  //   setResponse(emissionCategorySummariesLookupResponse)
  // }, [emissionCategorySummariesLookupResponse])
  // const {
  //   emissionFactorSummariesLookupResponse,
  // } = useEmissionFactorSummariesLookup()
  // useEffect(() => {
  //   setResponse(emissionFactorSummariesLookupResponse)
  // }, [emissionFactorSummariesLookupResponse])
  const {
    error: sessionTerminationError,
  } = useSessionTerminationState()
  useEffect(() => {
    setResponse(sessionTerminationError)
  }, [sessionTerminationError])

  const {
    error: clientRegistrationError,
  } = useClientRegistrationState()
  useEffect(() => {
    setResponse(clientRegistrationError)
  }, [clientRegistrationError])

  const {
    error: clientEmissionAssignmentError,
  } = useClientEmissionAssignmentState()
  useEffect(() => {
    setResponse(clientEmissionAssignmentError)
  }, [clientEmissionAssignmentError])

  const {
    error: clientAvatarUploadError,
  } = useClientAvatarUploadState()
  useEffect(() => {
    setResponse(clientAvatarUploadError)
  }, [clientAvatarUploadError])

  const {
    error: emissionSourcesSynchronizationError,
  } = useEmissionSourcesSynchronizationState()
  useEffect(() => {
    setResponse(emissionSourcesSynchronizationError)
  }, [emissionSourcesSynchronizationError])

  const {
    error: userClientAssignmentError,
  } = useUserClientAssignment()
  useEffect(() => {
    setResponse(userClientAssignmentError)
  }, [userClientAssignmentError])

  const {
    error: eligibilityCheckFinalizationError,
  } = useEligibilityCheckFinalizationState()
  useEffect(() => {
    setResponse(eligibilityCheckFinalizationError)
  }, [eligibilityCheckFinalizationError])

  return <></>
}
