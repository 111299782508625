import deepMerge from '@75lb/deep-merge'
import { useModifiedMaterialReactTable } from './modified_react_table'

/**
* Description
* @param {import('material-react-table').MRT_TableOptions<TData>} options
* @returns {any}
*/
export function useModifiedMaterialReactTableNested(options) {
  return useModifiedMaterialReactTable(deepMerge({
    enableColumnActions: false,
    enableRowActions: false,
    enablePagination: false,
    enableTableFooter: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableRowSelection: false,
    initialState: { density: 'compact' },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '7px',
        border: '1px solid #B3B4C6',
      }
    },
    muiTableBodyProps: () => ({
      sx: {
        '& :last-child > td': {
          borderBottomWidth: 0,
        },
      },
    }),
    muiTableBodyCellProps: ({ row, cell }) => {
      return {
        sx: {
          paddingBlock: '1rem',
        }
      }
    },
  }, options))
}
